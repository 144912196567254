<template>
    <section>
        <modal ref="modalAgregarLeecheros" titulo="Agregar leechero a otros cliente" no-aceptar adicional="Agregar" @adicional="agregar">
            <div class="row mx-0">
                <cargando v-if="cargando" />
                <div class="col px-0">
                    <div class="row justify-center mt-3">
                        <div class="col-10 text-general">
                            Agregar a <strong>{{ tienda.propietario_nombre }}</strong> a los clientes de otro {{$config.vendedor}}.
                        </div>
                    </div>
                    <div class="row justify-center mt-3">
                        <div class="col-10 text-general">
                            <p class="f-13 ml-2">Seleccionar {{$config.vendedor}}</p>
                            <el-select v-model="leechero" class="w-100" placeholder="Selecciona" size="small" :clearable="true" :filterable="true" :filter-method="getLeecheros" @clear="limpiar" @change="seleccionarLeechero">
                                <el-option
                                v-for="item in leecheros"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                    <template v-if="mostrarLeechero">
                        <div class="row justify-center mt-3">
                            <img :src="leecheroSeleccionado.logo" class="br-5 border obj-cover" style="width:110px;height:110px;" />
                            <div class="col-6 text-general">
                                <p class="text-general mt-1 f-500">
                                    {{ leecheroSeleccionado.nombre }}
                                </p>
                                <p class="text-general2 f-13">
                                    <i class="icon-calendar-outline f-19" />
                                    {{$config.vendedor}} desde {{ leecheroSeleccionado.created_at | helper-fecha('D MMM YYYY') }}
                                </p>
                                <p class="text-general2 f-13">
                                    <i class="icon-cliente f-19" />
                                    {{ leecheroSeleccionado.total_clientes }} Clientes  
                                </p>
                            </div>
                        </div>
                        <div class="row justify-center mt-5">
                            <div class="col-10 text-general">
                                Los <strong>{{ leecheroSeleccionado.total_clientes }}</strong> clientes de <strong>{{ leecheroSeleccionado.nombre }}</strong> tendrán a <strong>{{ tienda.propietario_nombre }}</strong> entre sus {{$config.vendedor}}s.
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Service from "~/services/clientes"
import Produccion from "~/services/tiendas/produccion";
export default {
    data(){
        return{
            cargando: false,
            mostrarLeechero: false,
            leecheros: [],
            leecheroSeleccionado: {},
            tienda: {},
            leechero: '',
        }
    },
    computed: {
        idTienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    methods: {
        toggle(){
            this.limpiar();
            this.info_tienda();
            this.$refs.modalAgregarLeecheros.toggle();
        },
        async info_tienda(){
            try {
                const { data } = await Produccion.info_tienda(this.$route.params.id_tienda)
                this.tienda = data.info;
            } catch (error){
                this.error_catch(error)
            }
        },
        async getLeecheros(query){
            try {
                if(query.length < 3)return this.limpiar();
                const params = {
                    query: query
                };
                const {data} = await Service.getLeecheros(params);
                this.leecheros = data.leecheros;
            } catch(error){
                this.error_catch(error)
            }
        },
        async agregar(){
            try {
                this.cargando = true;
                const form = {
                    leechero_entrega: this.leechero,
                    leechero_recibe: this.idTienda
                };
                const { data } = await Service.postAgregarLeecheros(form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.limpiar();
            } catch(error){
                this.error_catch(error)
            } finally {
                this.cargando = false;
            }
        },
        async seleccionarLeechero(){
            let data = this.leecheros.find((element) => element.id === this.leechero);
            if(data){
                this.leecheroSeleccionado = data;
                this.mostrarLeechero = true;
            }
        },
        async limpiar(){
            this.mostrarLeechero = false;
            this.leechero = "";
            this.leecheros = [];
            this.leecheroSeleccionado = {};
        },
    }
}
</script>
